var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center"},[_c('TMessage',{staticClass:"my-auto",attrs:{"content":"Auction logs","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.loading}}),_c('TButtonRefresh',{staticClass:"ml-auto",on:{"click":_vm.fetchAuctionLogs}})],1),_c('CCardBody',{staticClass:"w-100 mx-auto"},[_vm._l((_vm.logs),function(log){return _c('div',{key:log.id},[_c('CCard',[_c('CCardHeader',{staticClass:"d-flex align-items-center py-2 px-2",attrs:{"color":"light"}},[_vm._v(" #"),_c('TMessage',{attrs:{"content":log.id,"noTranslate":""}}),_c('CBadge',{staticClass:"ml-1",attrs:{"color":log.status == 'success' ? 'success' : 'danger'}},[_c('TMessage',{attrs:{"content":log.status == 'success' ? 'Success' : log.status}})],1)],1),_c('CCardBody',{staticClass:"p-2"},[_c('TTableAsForm',{staticClass:"mb-3",attrs:{"title":"","data":log,"fields":_vm.fields,"splitLeft":4},scopedSlots:_vm._u([{key:"data",fn:function(ref){
var value = ref.value;
return [_c('TMessage',{attrs:{"content":value,"bold":"","noTranslate":""}})]}},{key:"type",fn:function(ref){
var value = ref.value;
return [_c('TMessage',{attrs:{"content":value,"bold":"","noTranslate":""}})]}},{key:"link",fn:function(ref){
var value = ref.value;
return [_c('td',[(value)?_c('TLink',{attrs:{"href":value,"content":value}}):_c('TMessage',{attrs:{"content":value,"noTranslate":""}})],1)]}},{key:"price",fn:function(ref){
var value = ref.value;
return [_c('TMessageMoney',{attrs:{"amount":value,"currency":"JPY"}})]}},{key:"date_bid",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value}})]}},{key:"date_finish",fn:function(ref){
var value = ref.value;
return [_c('TMessageDateTime',{attrs:{"content":value}})]}}],null,true)})],1)],1)],1)}),(!_vm.logs.length)?_c('TMessageNoData'):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }